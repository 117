<template>
  <b-card
    no-body
    class="mb-1"
  >
    <b-card-header class="px-2 py-1 pb-50">
      <h4 class="card-title font-weight-bolder">
        {{ $t('filters') }}
      </h4>
      <div class="d-flex align-items-center justify-content-end">
        <!-- SEARCH BUTTON -->
        <b-button
          style="min-width: 120px"
          :variant="!emptyFilters ? 'warning' : ''"
          class="mr-1"
          :class="{ 'cursor-not-allowed': emptyFilters }"
          :disabled="emptyFilters"
          @click="onUpdateFilters"
        >
          <span class="text-nowrap">
            <feather-icon
              class="cursor-pointer"
              icon="SearchIcon"
              size="16"
            />
            {{ $t('search') }}
          </span>
        </b-button>

        <!-- CLEAR BUTTON -->
        <b-button
          style="min-width: 120px"
          :variant="!emptyFilters ? 'danger' : ''"
          :class="{ 'cursor-not-allowed': emptyFilters }"
          :disabled="emptyFilters"
          @click="onClearFilters"
        >
          <span class="text-nowrap">
            <feather-icon
              class="cursor-pointer"
              icon="XOctagonIcon"
              size="16"
            />
            {{ $t('clear') }}
          </span>
        </b-button>
      </div>
    </b-card-header>

    <b-card-body class="py-0">
      <b-row>
        <!-- ACTION-->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label-class="h5"
            :label="$t('auditLog.columns.action')"
          >
            <b-form-input
              v-model.trim="action"
              :placeholder="$t('auditLog.placeholderAction')"
            />
          </b-form-group>
        </b-col>

        <!-- REFERENCE ID -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label-class="h5"
            :label="$t('auditLog.columns.referenceId')"
          >
            <b-form-input
              v-model.trim="refId"
              v-remove-non-numeric-chars
              :placeholder="$t('auditLog.placeholderRefId')"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  ref, computed,
} from '@vue/composition-api'
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'

import { DEFAULT_FEATURE } from '@/constants/auditLog'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BFormGroup,
    BFormInput,
  },

  props: {
    feature: {
      type: String,
      default: DEFAULT_FEATURE,
    },
  },

  setup(props, { emit }) {
    const action = ref('')
    const refId = ref('')

    const emptyFilters = computed(() => !(
      action.value
      || refId.value
      || props.searchKeywords
    ))

    const onClearFilters = () => {
      action.value = ''
      refId.value = ''
      emit('update:searchKeywords', '')
      emit('refetch-data-without-filters')
    }

    const onUpdateFilters = () => {
      const filtersData = {
        feature: props.feature,
        action: action.value || undefined,
        refId: refId.value || undefined,
      }
      emit('refetch-data-with-filters', filtersData)
    }

    return {
      action,
      refId,
      emptyFilters,

      onClearFilters,
      onUpdateFilters,
    }
  },
}
</script>

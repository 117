var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-tabs', {
    attrs: {
      "pills": "",
      "lazy": ""
    }
  }, _vm._l(_vm.FEATURES, function (feature, key) {
    return _c('b-tab', {
      key: key,
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_c('span', {
            staticClass: "d-none d-sm-inline"
          }, [_vm._v(" " + _vm._s(_vm.$t("auditLog.features.".concat(key))) + " ")])];
        },
        proxy: true
      }], null, true)
    }, [_c('AuditLogList', {
      attrs: {
        "feature": feature
      }
    })], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
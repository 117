export const AUDIT_LOG_APP_STORE_MODULE_NAME = 'app-audit-log'

export const AUDIT_LOG_TABLE_COLUMNS = [
  // { label: 'checkbox', key: 'checkbox', sortable: false },
  {
    label: 'referenceId', key: 'referenceId', sortable: false, thStyle: { width: '100px' },
  },
  // { label: 'refResource', key: 'refResource', sortable: false },
  {
    label: 'action', key: 'action', sortable: false, thStyle: { width: '150px' },
  },
  {
    label: 'modifiedBy', key: 'modifiedBy', sortable: false, thStyle: { width: '150px' },
  },
  {
    label: 'oldValue', key: 'oldValue', sortable: false,
  },
  {
    label: 'newValue', key: 'newValue', sortable: false,
  },
  {
    label: 'timestamp', key: 'timestamp', sortable: true, thStyle: { width: '200px' },
  },
  // { label: 'created', key: 'createdAt', sortable: true },
  // { label: 'updated', key: 'updatedAt', sortable: true },
]

export const DEFAULT_FEATURE = 'BOOKING'

export const FEATURES = {
  booking: 'BOOKING',
  // reservation: 'RESERVATION',
  // ticket: 'TICKET',
  agency: 'AGENCY',
  customer: 'CUSTOMER',
  employee: 'EMPLOYEE',
  // invoice: 'INVOICE',
  topUp: 'TOP_UP',
  promotion: 'PROMOTION',
  feeConfig: 'FEE_CONFIG',
  feeService: 'FEE_SERVICE',
  agencyBankAccount: 'AGENCY_BANK_ACCOUNT',
}

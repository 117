var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "mb-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    staticClass: "px-2 py-1 pb-50"
  }, [_c('h4', {
    staticClass: "card-title font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('filters')) + " ")]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-end"
  }, [_c('b-button', {
    staticClass: "mr-1",
    class: {
      'cursor-not-allowed': _vm.emptyFilters
    },
    staticStyle: {
      "min-width": "120px"
    },
    attrs: {
      "variant": !_vm.emptyFilters ? 'warning' : '',
      "disabled": _vm.emptyFilters
    },
    on: {
      "click": _vm.onUpdateFilters
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "SearchIcon",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('search')) + " ")], 1)]), _c('b-button', {
    class: {
      'cursor-not-allowed': _vm.emptyFilters
    },
    staticStyle: {
      "min-width": "120px"
    },
    attrs: {
      "variant": !_vm.emptyFilters ? 'danger' : '',
      "disabled": _vm.emptyFilters
    },
    on: {
      "click": _vm.onClearFilters
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "XOctagonIcon",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('clear')) + " ")], 1)])], 1)]), _c('b-card-body', {
    staticClass: "py-0"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5",
      "label": _vm.$t('auditLog.columns.action')
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('auditLog.placeholderAction')
    },
    model: {
      value: _vm.action,
      callback: function callback($$v) {
        _vm.action = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "action"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5",
      "label": _vm.$t('auditLog.columns.referenceId')
    }
  }, [_c('b-form-input', {
    directives: [{
      name: "remove-non-numeric-chars",
      rawName: "v-remove-non-numeric-chars"
    }],
    attrs: {
      "placeholder": _vm.$t('auditLog.placeholderRefId')
    },
    model: {
      value: _vm.refId,
      callback: function callback($$v) {
        _vm.refId = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "refId"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'

import {
  AUDIT_LOG_TABLE_COLUMNS as tableColumns,
  DEFAULT_FEATURE,
} from '@/constants/auditLog'
import store from '@/store'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useAuditLogHandle() {
  const refAuditLogListTable = ref(null)
  const loading = ref(null)
  const searchKeywords = ref(null)
  const totalAuditLog = ref(0)
  const sizePerPage = ref(40)
  const currentPage = ref(1)
  const sortBy = ref(null) // createdAt || updatedAt
  const isSortDirDesc = ref(true) //  ASC || DESC
  const selectedIds = ref([])
  const fixedFeature = ref(DEFAULT_FEATURE)

  const dataMeta = computed(() => {
    const localItemsCount = refAuditLogListTable.value
      ? refAuditLogListTable.value.localItems.length
      : 0
    return {
      from:
        sizePerPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: sizePerPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalAuditLog.value,
    }
  })

  const toast = useToast()

  let filtersParams = {}

  const fetchAuditLogs = (ctx, callback) => {
    loading.value = true
    store
      .dispatch('app-audit-log/fetchAuditLogs', {
        feature: fixedFeature.value,
        ...filtersParams, // action, refId
        // timestamp: undefined,
        searchText: searchKeywords.value || undefined,
        sortBy: sortBy.value ? sortBy.value : 'createdAt',
        sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
        size: sizePerPage.value,
        page: currentPage.value,
      })
      .then(({ data }) => {
        totalAuditLog.value = data.total
        callback(data.items)
        loading.value = false
      })
      .catch(error => {
        console.error('error', error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching audit logs list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        loading.value = false
      })
  }

  const refetchData = ($event = {}) => {
    filtersParams = { ...$event }
    refAuditLogListTable.value.refresh()
  }
  const clearFilter = () => {
    searchKeywords.value = null
  }
  const isChecked = id => selectedIds.value.includes(id)
  const setArray = (array, id) => {
    const index = array ? array.findIndex(element => element === id) : -1
    // eslint-disable-next-line no-unused-expressions
    index >= 0 ? array.splice(index, 1) : array.push(id)

    return array
  }
  const chooseItem = id => {
    selectedIds.value = setArray(selectedIds.value, id)
  }

  watch(
    [currentPage, sizePerPage, sortBy, isSortDirDesc, searchKeywords],
    () => {
      refetchData()
    },
  )

  return {
    tableColumns,

    refAuditLogListTable,
    loading,
    searchKeywords,
    totalAuditLog,
    sizePerPage,
    currentPage,
    sortBy,
    isSortDirDesc,
    dataMeta,
    fixedFeature,

    fetchAuditLogs,
    refetchData,
    clearFilter,
    isChecked,
    chooseItem,
  }
}

import { apiAuditLog } from '@/api/'

export default {
  namespaced: true,

  actions: {
    async addAuditLog(_ctx, payload) {
      const res = await apiAuditLog.addAuditLog(payload)
      return res
    },

    async fetchAuditLogs(_ctx, params) {
      const res = await apiAuditLog.fetchAuditLogs(params)
      return res
    },
  },
}

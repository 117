<template>
  <div>
    <!-- FILTERS -->
    <AuditLogListFilters
      :feature="feature"
      @refetch-data-with-filters="refetchData"
      @refetch-data-without-filters="clearFilter"
    />
    <b-card
      style="max-height: 90vh"
      no-body
      class="p-1"
    >
      <!-- TABLE HEADER -->
      <div class="mb-1">
        <b-row>
          <!-- PER PAGE -->
          <b-col
            cols="4"
            md="auto"
          >
            <v-select
              v-model="sizePerPage"
              :options="sizePerPageLgOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            >
              <template #option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>

              <template #selected-option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>
            </v-select>
          </b-col>
        </b-row>
      </div>

      <!-- TABLE CONTENT -->
      <b-overlay
        :show="loading"
        rounded="sm"
        no-fade
        variant="transparent"
        :opacity="0.5"
      >
        <b-table
          ref="refAuditLogListTable"
          style="max-height: 70vh"
          sticky-header
          responsive
          show-empty
          primary-key="id"
          class="position-relative"
          :items="fetchAuditLogs"
          :fields="tableColumns"
          :sort-by.sync="sortBy"
          :empty-text="$t('noRecordFund')"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- COLUMNS HEADER -->
          <template
            v-for="column in tableColumns"
            v-slot:[`head(${column.key})`]="data"
          >
            <span
              :key="column.label"
              class="text-dark text-nowrap"
            >
              {{ $t(`auditLog.columns.${data.label}`) }}
            </span>
          </template>

          <template #cell(timestamp)="data">
            <span class="text-nowrap">
              {{ convertISODateTime(data.item.timestamp).dateTime }}
            </span>
          </template>

          <template #cell(modifiedBy)="{ item }">
            <div
              v-if="item?.modifiedBy"
              v-b-tooltip.hover.html.v-primary.window
              style="max-width: 250px;"
              :title="`<div class='d-flex flex-column align-items-start gap-2 font-small-4'>
                  <div>Username: <span class='text-uppercase'>${item?.modifiedBy?.username }</span></div>
                  <span>Tên: ${item?.modifiedBy?.lastName} ${item?.modifiedBy?.firstName}</span>
                  <span>Mã user: ${item?.modifiedBy?.employeeCode}</span>
                  </div>`"
            >
              <strong class="mb-25 text-uppercase">
                {{ item?.modifiedBy?.username }}
              </strong>
              <div
                v-if="item?.modifiedBy?.lastName || item?.modifiedBy?.firstName"
                class="text-truncate"
              >
                ({{ item?.modifiedBy?.lastName }} {{ item?.modifiedBy?.firstName }})
              </div>
            </div>
          </template>

          <template #cell(newValue)="data">
            <div class="d-flex-center justify-content-start gap-2">
              <div
                class="text-truncate"
                style="max-width: 400px;"
              >
                {{ data.item.newValue }}
              </div>
              <b-button
                v-if="data.item.newValue"
                v-b-tooltip.hover.window
                v-b-modal.modal-audit-log-fulltext
                title="Xem đầy đủ"
                variant="flat-dark"
                class="rounded-circle p-50"
                @click="fullText = data.item.newValue"
              >
                <feather-icon
                  icon="MoreHorizontalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </b-button>
            </div>
          </template>
          <template #cell(oldValue)="data">
            <div class="d-flex-center justify-content-start gap-2">
              <div
                class="text-truncate"
                style="max-width: 400px;"
              >
                {{ data.item.oldValue }}
              </div>
              <b-button
                v-if="data.item.oldValue"
                v-b-tooltip.hover.window
                v-b-modal.modal-audit-log-fulltext
                title="Xem đầy đủ"
                variant="flat-dark"
                class="rounded-circle p-50"
                @click="fullText = data.item.oldValue"
              >
                <feather-icon
                  icon="MoreHorizontalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-overlay>

      <!-- TABLE FOOTER -->
      <div class="mx-2 mb-1">
        <b-row>
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-center"
          >
            <span class="text-muted">
              {{ dataMeta.from }} {{ $t('paginationText.to') }} {{ dataMeta.to }} {{ $t('paginationText.outOf') }}
              {{ dataMeta.of }} {{ $t('paginationText.items') }}
            </span>
          </b-col>
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalAuditLog"
              :per-page="sizePerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-modal
      id="modal-audit-log-fulltext"
      title="Nội dung"
      centered
      hide-footer
      size="md"
    >
      <pre
        class="m-0 py-1"
        style="max-height: 80vh; overflow: auto;"
      >
        {{ parseText(fullText) }}
      </pre>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
  BOverlay, BButton, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'

import {
  sizePerPageLgOptions,
} from '@/constants/selectOptions'
import { DEFAULT_FEATURE } from '@/constants/auditLog'

import { convertISODateTime } from '@core/utils/filter'

import useAuditLogHandle from '../useAuditLogHandle'
import AuditLogListFilters from './AuditLogListFilters.vue'

export default {
  components: {
    AuditLogListFilters,
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BOverlay,
    BButton,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    feature: {
      type: String,
      default: DEFAULT_FEATURE,
    },
  },
  setup(props) {
    const {
      tableColumns,
      refAuditLogListTable,
      loading,
      searchKeywords,
      totalAuditLog,
      sizePerPage,
      currentPage,
      sortBy,
      isSortDirDesc,
      dataMeta,
      fixedFeature,

      fetchAuditLogs,
      refetchData,
      clearFilter,
    } = useAuditLogHandle()

    fixedFeature.value = props.feature

    const fullText = ref('')

    function parseText(text) {
      try {
        return (JSON.parse(text.trim()))
      } catch (error) {
        return text.trim()
      }
    }
    return {
      sizePerPageLgOptions,
      convertISODateTime,

      // returned by useAuditLogHandle()
      tableColumns,
      refAuditLogListTable,
      loading,
      searchKeywords,
      totalAuditLog,
      sizePerPage,
      currentPage,
      sortBy,
      isSortDirDesc,
      dataMeta,

      fetchAuditLogs,
      refetchData,
      clearFilter,
      fullText,
      parseText,
    }
  },
}
</script>

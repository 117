<template>
  <div>
    <b-tabs
      pills
      lazy
    >
      <b-tab
        v-for="(feature, key) in FEATURES"
        :key="key"
      >
        <template #title>
          <span class="d-none d-sm-inline">
            {{ $t(`auditLog.features.${key}`) }}
          </span>
        </template>
        <AuditLogList
          :feature="feature"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTab, BTabs,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'

import store from '@/store'
import {
  AUDIT_LOG_APP_STORE_MODULE_NAME as AUDIT_LOG_STORE,
  FEATURES,
} from '@/constants/auditLog'

import AuditLogList from './AuditLogList.vue'
import auditLogStoreModule from '../auditLogStoreModule'

export default {
  components: {
    BTab,
    BTabs,
    AuditLogList,
  },
  setup() {
    // Register module
    if (!store.hasModule(AUDIT_LOG_STORE)) store.registerModule(AUDIT_LOG_STORE, auditLogStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AUDIT_LOG_STORE)) store.unregisterModule(AUDIT_LOG_STORE)
    })

    return {
      FEATURES,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
